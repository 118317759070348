import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../../../components/layout"
import { faLink, faPhone, faEnvelope, faAt, faPaperPlane, faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import * as PropTypes from "prop-types"

class RatingModel extends React.Component {
  constructor(props) {
    super(props)
    this.state = { rating: this.props.value ?? 0 }
  }

  setRating(rating) {
    console.log("rating " + rating)
    this.setState({ rating })
    this.props.setRating(rating)
  }

  render() {
    return <div style={{ width: "fit-content", margin: "20px auto" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ margin: "0 10px", display: "flex" }}>
          {
            [1, 2, 3, 4, 5].map(rating => <a key={"rating" + rating}
                                             className={"btn btn-primary-outline " + (rating === this.state.rating ? " active" : "")}
                                             onClick={() => this.setRating(rating)}>{rating}</a>)
          }
        </div>
      </div>
      <div style={{ margin: "10px 0", display: "flex", justifyContent: "space-between" }}>
        <div>Nicht {this.props.term}</div>
        <div>Sehr {this.props.term}</div>
      </div>
    </div>
  }
}

const queryParams = new URLSearchParams((typeof window !== "undefined") ? window.location.search : null)

export default class SurveyConversation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      success: false,
      loading: false,
      rating_solved: -1,
      rating_usertext: "",
      rating_helpful: parseInt(queryParams.get("rating_helpful")),
    }
  }

  submit() {
    this.setState({ loading: true })
    this.callAPI(true)
  }

  setRating(rating) {
    this.setState({
      step: this.state.step + 1,
      rating_solved: rating,
    }, this.callAPI.bind(this))
  }

  callAPI(setSuccess = false) {
    console.log("calling api")

    fetch("https://api.ziegenhagel.com/noauth/" + queryParams.get("id") + "/survey/", {
      method: "post",
      body: JSON.stringify({
        rating_helpful: queryParams.get("rating_helpful"),
        rating_solved: this.state.rating_solved,
        rating_usertext: this.state.rating_usertext,
      }),
    }).then(d => d.json()).then(d => {
      this.setState({ success: (this.state.rating_solved == 5 || setSuccess) && d.success, loading: false })
      console.log("api survey", d)
    })
  }

  componentDidMount() {
    this.callAPI()
  }


  setSuccess(success) {
    this.setState({
      success: true,
      loading: false,
    })
  }

  render() {
    return (<Layout container={true}>
        <div className={"text-center mb-10"}>
          <div className={this.state.loading ? "pending" : ""}>
            <h1
              className="post-heading text-center">{this.state.success ? "Danke für Ihre Bewertung!" : "Wie war die Antwort von Dominik?"}</h1>
            {!this.state.success ?
              <>
                <div style={{ margin: "20px 0" }}>
                  <p style={{ margin: 0 }}>Wie hilfreich war diese Antwort?</p>
                  <RatingModel setRating={(rating) => this.setState({ rating_helpful: rating })}
                               key={this.state.rating_helpful}
                               value={this.state.rating_helpful} term={"hilreich"}></RatingModel>
                  <span style={{visibility:"hidden"}}>{this.state.rating_helpful}</span>
                </div>
                <>
                  <p>Mein Problem wurde zu meiner Zufriedenheit gelöst.</p>
                  <RatingModel setRating={this.setRating.bind(this)} term={"zufrieden"}></RatingModel>
                </>
                {
                  this.state.rating_solved > 0 && this.state.rating_solved < 5 &&
                  <div action="https://api.ziegenhagel.com/sendmail.php" className="bigform">
                    <textarea placeholder={"Das hätte ich mir gewünscht ..."}
                              onKeyUp={e => this.state.rating_usertext = e.target.value}>{this.state.rating_usertext}</textarea>
                    <button onClick={this.submit.bind(this)}>Absenden <FontAwesomeIcon
                      icon={faPaperPlane}></FontAwesomeIcon>
                    </button>
                  </div>
                }
              </> : <p>Sie können diese Seite jetzt schliessen.</p>
            }
          </div>
          {this.state.loading && <div><FontAwesomeIcon icon={faCircleNotch} className={"fa-spin"} /> Senden ...</div>}
        </div>
      </Layout>
    )
  }
}


